import React from "react";
import {
  SEO,
  PageLayout,
  Background,
  SocialMedia,
  Content,
  CallToActionBanner,
  Iterator,
  StaffTeamMember,
} from "@bluefin/components";
import { Grid, Header, Card } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class IndexPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const {
      fishermanBusiness,
      fishermanBusinessWebsitePage,
      allFishermanBusinessTeam,
    } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout
          hero={
            <Background
              columns={1}
              stackable={true}
              className={"home-hero desktop-container"}
            >
              <Background.Image
                images={getComponentFiles({
                  components: fishermanBusinessWebsitePage.components,
                  componentName: "HeroElement",
                })}
                background={true}
                forceChildren={true}
              >
                <div className={"welcome-content-container desktop-only"}>
                  <Grid className={"welcome-content"} stackable={true}>
                    <Grid.Row verticalAlign={"middle"}>
                      <Grid.Column
                        width={8}
                        textAlign={"left"}
                        className={"title-column"}
                      >
                        <Header as={"h2"}>WELCOME</Header>
                      </Grid.Column>
                      <Grid.Column
                        width={8}
                        textAlign={"right"}
                        className={"social-media-column"}
                      >
                        <SocialMedia
                          socialMediaValues={fishermanBusiness.socialMedia}
                          buttonType={"circle"}
                          centerHeader={false}
                          colors={"white"}
                          groupButtons={false}
                          groupVertically={false}
                          inverted={true}
                          labelPosition={"free"}
                          showLabels={false}
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Column width={16} className={"welcome-copy"}>
                      <Content stackable={true} textAlign={"left"}>
                        <Content.Markup
                          width={16}
                          isMarkdown={true}
                          content={fishermanBusiness.aboutMarkdown}
                        />
                      </Content>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <CallToActionBanner stackable={true} />
                    </Grid.Column>
                  </Grid>
                </div>
                <div className={"mobile-only"}>
                  <Header
                    content={fishermanBusiness.name}
                    as={"h1"}
                    inverted={true}
                  />
                </div>
              </Background.Image>
            </Background>
          }
          subfooter={false}
          className={"home-page"}
        >
          <Grid stackable={true} className={"home-hero mobile-only"}>
            <Grid.Column>
              <div className={"welcome-content-container desktop"}>
                <Grid className={"welcome-content"} stackable={true}>
                  <Grid.Row verticalAlign={"middle"}>
                    <Grid.Column
                      width={8}
                      textAlign={"left"}
                      className={"title-column"}
                    >
                      <Header as={"h2"}>WELCOME</Header>
                    </Grid.Column>
                    <Grid.Column
                      width={8}
                      textAlign={"right"}
                      className={"social-media-column"}
                    >
                      <SocialMedia
                        socialMediaValues={fishermanBusiness.socialMedia}
                        buttonType={"circle"}
                        centerHeader={false}
                        colors={"white"}
                        groupButtons={false}
                        groupVertically={false}
                        inverted={true}
                        labelPosition={"free"}
                        showLabels={false}
                      />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Column width={16} className={"welcome-copy"}>
                    <Content stackable={true} textAlign={"left"}>
                      <Content.Markup
                        width={16}
                        isMarkdown={true}
                        content={fishermanBusiness.aboutMarkdown}
                      />
                    </Content>
                  </Grid.Column>
                  <Grid.Column width={16}>
                    <CallToActionBanner stackable={true} />
                  </Grid.Column>
                </Grid>
              </div>
            </Grid.Column>
          </Grid>
          <Grid
            columns={2}
            stackable={true}
            doubling={true}
            className={"home-content"}
          >
            <Grid.Column
              className={"about-image-gallery-container"}
              width={10}
              textAlign={"left"}
            >
              <Header as={"h2"} className={"team-members-header"}>
                Our Barbers
              </Header>
              <Card.Group className={"team-members"}>
                <Iterator
                  iterator={allFishermanBusinessTeam.nodes}
                  key={"name"}
                  component={StaffTeamMember}
                  propMap={{
                    name: "name",
                    role: "role",
                    email: "email",
                    phone: "phone",
                    description: "description",
                    photo: "gatsbyImage",
                    social: "socialMedia",
                    hours: "hours",
                    locations: "locations",
                  }}
                />
              </Card.Group>
            </Grid.Column>
            <Grid.Column width={6}>
              <div>
                <h2>No-Show Policy:</h2>
                <p>
                  We understand that unforeseen circumstances may arise, but we
                  kindly request that you provide at least 2 hours notice if you
                  need to cancel or reschedule your appointment. Failure to do
                  so will result in a charge of 50% of the scheduled service
                  cost.
                </p>
                <h2>Late Arrival Policy:</h2>
                <p>
                  Clients who arrive more than 15 minutes late may be asked to
                  reschedule and will also be charged 50% of the missed service
                  cost.
                </p>
                <p>
                  We appreciate your understanding and cooperation in respecting
                  our time and schedule.
                </p>
                <p>Thank You</p>
                <p>The Don's Barbershop Staff</p>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      socialMedia {
        link
        type
      }
      aboutMarkdown
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Home" }) {
      title
      description
      components {
        fastId
        componentIdentifier
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
    allFishermanBusinessTeam(filter: { show: { eq: true } }) {
      nodes {
        _id
        name
        role
        group
        email
        phone
        description
        image
        gatsbyImage {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        socialMedia {
          platform
          url
        }
        hours {
          startDay
          openTime
          closeTime
        }
        locations {
          _id
          name
        }
        show
      }
    }
  }
`;
